.service-item{
	.hover-link-wrap {
		left: 50%;
		opacity: 0;
		position: absolute;
		top: 20%;
		transition-duration: 0.3s;
		z-index: 9;
		transform: translate(-50%, -50%);
		i {
			color: #fff;
			display: block;
			font-size: 20px;
			padding: 0;
			background: #f5af24;
			border-radius: 100%;
			height: 50px;
			width: 50px;
			font-size: 22px;
			line-height: 50px;
		}
		.hover-link{
			text-align: center;
			&:hover{
				text-decoration: none;
				i{
					color: $text_c;
				}

			}
		}
	}

	p {
		margin-bottom: 20px;
		height: 72px;
		overflow: hidden;
	}
	&:hover{
		.hover-link-wrap {
			opacity: 1;
			top: 50%;
		}
		.service-img{
			&:after {
				opacity: 1;
			}
		}
	}
}
.service-item-title{

	font-size: 1.4rem;

	a{
		color: $text_c;
		&:hover{
			color: $accent_color;
		}
	}
}
.service-img {
	position: relative;
	overflow: hidden;
	&:after {
		content: "";
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition-duration: 0.3s;
		width: 100%;
		background: rgba(2, 27, 39, 0.8);
	}
}
.service-item-title{
	margin-bottom: 10px;
	margin-top: 15px;
	font-size: 20px;
}

