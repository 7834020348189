.news-block{
	margin-bottom: 30px;
	transition: all .5s ease;
	&:hover {
		box-shadow: 0 0 10px 5px #ddd;
		margin-top: -5px;
	}
	.image {
		position: relative;
		.post-date {
			position: absolute;
			right: 0px;
			top: 0px;
			font-size: 25px;
			color: #ffffff;
			font-weight: bold;
			padding: 5px 15px 5px 5px;
			display: inline-block;
			background-color: #f5af24;
			z-index: 1;
			text-transform: uppercase;
			&:before {
				content: "";
				width: 0;
				height: 0;
				border: 24px solid transparent;
				border-top-color: #f5af24;
				position: absolute;
				left: -24px;
				top: 0;
				z-index: -1;
				border-top-width: 52px;
			}
			span {
				font-size: 14px;
			}
		}
	}
	.lower-content {
		border: 1px solid #eee;
		padding: 20px 20px 20px;
		background: #fff;
		.news-block-title {
			height: 50px;
			overflow: hidden;
			margin-bottom: 10px;
			margin-top: 5px;
			font-size: 18px;
			line-height: 27px;
			a{
				color: $text_c;
			}
		}
		.text {
			margin-bottom: 12px;
		}
		.metalink {
			border-top: 1px solid #eee;
			padding-top: 14px;
			margin-top: 20px;
		}
		 .detail {
			color: $accent_color;
			font-weight: 400;

		}
	}
}