/***
====================================================================
	Fonts
====================================================================
 ***/
$font_primary: 'Roboto', sans-serif;
/***
====================================================================
	Colors
====================================================================
 ***/

$blue_c: #0d396a;
$blue_second_c: #0f539a;
$red_c: #c52f38;
$text_c: #333;


$accent_color: #c52f38;

$primary_text_color: #212121;
$secondary_text_color: #757575;
$divider_color: #d1d1d1;

$logo_text_dark: #555555;
$logo_text_light: #898989;

$grey_bg: #f5f5f5;
$dark_blue: #0b2028;

$gradient_color: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);

/***
====================================================================
	transition
====================================================================
 ***/
$blog_transition: all .3s ease-out;
/***
====================================================================
	clearfix
====================================================================
 ***/
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
@for $i from 1 through 10 {
	.spacer#{$i}0 {
		height: #{$i}0px;
	}
}

/***
====================================================================
	media queries
====================================================================
 ***/
$min400: 400px;
$max400: max-width 399px;
$min480: 480px;
$max480: max-width 479px;
$min576: 576px;
$max576: max-width 575px;
$min768: 768px;
$max768: max-width 767px;
$min992: 992px;
$max992: max-width 991px;
$min1200: 1200px;
$max1200: max-width 1199px;
$min1600: 1600px;
$max1600: max-width 1599px;
/*
media query example

@include breakpoint($min768) {
		position: fixed;
	}
*/

/***
====================================================================
	Mixins for Rem Font Sizing
====================================================================
 ***/
//
//@mixin font-size($sizeValue: 16) {
//	font-size: $sizeValue  + px;
//	font-size: ($sizeValue / 10) + rem;
//}
/*
@include font-size(16); -- 16px 1.6rem
*/
