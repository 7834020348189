
.entry-prev-title {
	font-size: 1.4rem;
	a {
		color: $text_c;
		padding: 10px 0;
		display: block;
	}
}

.entry-prev {
	border-bottom: 1px solid #dedede;
	padding-top: 15px;
	margin-bottom: 30px;
	img {
		height: auto;
		width: 100%;
	}
	.date-objava {
		color: lighten($text_c, 50%);
		margin-bottom: 10px;
	}

}

.entry-prev__excerpt {
	margin-bottom: 20px;
}


.post-nav-image-link{
	display: block;
	margin-bottom: 5px;
}

/**
post-nav
 */
.post-nav-item{
	background-color: #fff;
}
.post-nav-link {
	display: block;
	background-color: $blue_c;
	color: #fff;
	padding: 5px 15px;
	&.next {
		text-align: right;
	}
	&:hover {
		text-decoration: none;
		color: $red_c;
	}

}
.post-nav-header{
padding: 0 15px;
	height: 46px;
	overflow: hidden;
}
.post-nav-title {

	font-size: 1.2rem;
	font-weight: 700;
	a{
		color: $text_c;
		&:hover {
			text-decoration: none;
			color: $red_c;
		}
	}

}
.post-nav-excerpt{
	padding: 10px 15px 30px 15px;
}