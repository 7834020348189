.btn-style-one {
	position: relative;
	padding: 8px 30px;
	line-height: 24px;
	text-transform: uppercase;
	background: #0b2028;
	color: #ffffff !important;
	font-size: 14px;
	font-weight: 500;
	border: 2px solid #0b2028 !important;
	border-radius: 2px;
	font-family: 'Roboto', sans-serif;
	&:hover {
		color: #0b2028 !important;
		border-color: #0b2028 !important;
		background: none;
	}
}

.read-more-btn {
	color: #fff;
	border-radius: 2px;
	background-color: $red_c;
	border-color: $red_c;
	font-size: 1rem;
	transition: all .5s ease;
	&:hover {
		color: $text_c;
	}

}

.round-social-btn {
	display: flex;
	background: #0e2a33;
	border-radius: 50px;
	color: #cacfd3;
	padding: 0;
	font-size: 1rem;

	line-height: 1;

	text-align: center;
	transition: all 0.3s ease 0s;

	.fa {
		margin-right: 0;
		color: #fff;
		font-size: 1rem;
		width: 35px;
		height: 35px;
		text-align: center;
		line-height: 35px;
		background-color: $accent_color;
		color: #fff;
		border-radius: 30px;
	}
	.text {
		padding-right: 20px;
		padding-left: 15px;
	}
	&:hover,&:focus{
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);

		.text{
			color: $accent_color;
		}
	}
}

.blurslider-gradient {
	background: -webkit-linear-gradient(left, #FF0080, #FF8C00, #40E0D0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.blurslider-line {
	background: -webkit-linear-gradient(left, #FF0080, #FF8C00, #40E0D0) !important;
}

.tp-bannertimer {
	height: 2px !important;
	background: #40E0D0; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0) !important; /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.btn-edit{
	color: #fff;
	border-radius: 2px;
	background-color: $red_c;
	border-color: $red_c;
	font-size: 1rem;
	transition: all .5s ease;
	//padding-left: 30px;
	&:before{
		content: '\f013';
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: 14px;
	padding-right: 10px;
		text-rendering: auto;
	}
	&:hover {
		color: $text_c;
	}
}

.btn-form{
	color: #fff;
	border-radius: 2px;
	background-color: $red_c;
	border-color: $red_c;
	font-size: 1rem;
	transition: all .5s ease;
	//padding-left: 30px;
	&:before{
		content: '\f013';
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: 14px;
		padding-right: 10px;
		text-rendering: auto;
	}
	&:hover {
		color: $text_c;
	}
}