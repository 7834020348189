.main-header {
	//position: absolute;
	//left: 0px;
	//top: 0px;
	//z-index: 999;
	//width: 100%;
	font-family: 'Roboto', sans-serif;
	transition: all 500ms ease;
	//margin-bottom: 30px;
}

.main-header .header-top {
	position: relative;
	font-size: 13px;
	padding: 9px 0px 7px;
	background: #2c2e35;

	@include breakpoint($min480) {
		background: $red_c;
	}
	color: #ffffff;
	font-family: 'Roboto', sans-serif;
}

.main-header .header-top a {
	color: #ffffff;
	transition: all 300ms ease;
}

.main-header .links-nav {
	//position: relative;
	//float: left;
	//margin-right: 25px;
}

.main-header .top-right .links-nav {
	margin-right: 0px;
	margin-left: 25px;
}

.main-header .links-nav a {
	position: relative;
	line-height: 26px;
	font-size: 13px;
	text-transform: capitalize;
	&.active {
		color: $red_c;
		@include breakpoint($min480) {
			color: $text_c;
		}
	}
}

.main-header .links-nav .fa {
	padding-right: 8px;
	font-size: 16px;
	color: #fff;
}

.main-header .header-top .social-links a {
	position: relative;
	display: inline-block;
	margin-left: 20px;
	font-size: 13px;
	font-weight: normal;
	color: #ffffff;
	line-height: 26px;
}

.main-header .header-top a:hover {
	color: $text_c;
}

.header-upper__logo-wrap {
	position: relative;
	height: 100px;
}

.header-logo {
	position: absolute;
	top: -50px;
	left: 0;
	.logo-compose-img {
		width: 180px;
		height: 180px;
	}
}

/**
------------------------------------------------------------------------------------------------------------------------
language-chooser
------------------------------------------------------------------------------------------------------------------------
 */
#alphabet-chooser {
	&.cir {
		a:first-child {
			color: $accent_color;
		}
	}
	&.lat {
		a:last-child {
			color: $accent_color;
		}
	}

}

/**
------------------------------------------------------------------------------------------------------------------------
header-top
------------------------------------------------------------------------------------------------------------------------
 */
.header-top {
	.phone-wrap {
		@include breakpoint($min992) {
			margin-left: 180px;
		}

		position: relative;
		z-index: 50;
	}
}

.header-top__phone, .header-top__work-hours {
	display: none;
	@include breakpoint($min480) {
		display: inline-block;
	}

}

/**
------------------------------------------------------------------------------------------------------------------------
header-upper
------------------------------------------------------------------------------------------------------------------------
 */
.header-upper {
	background-color: $blue_second_c;
	background: -moz-linear-gradient(left, rgba(15, 83, 154, 1) 25%, rgba(255, 255, 255, 0) 58%);
	background: -webkit-linear-gradient(left, rgba(15, 83, 154, 1) 25%, rgba(255, 255, 255, 0) 58%);
	background: linear-gradient(to right, rgba(15, 83, 154, 1) 25%, rgba(255, 255, 255, 0) 58%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f539a', endColorstr='#00ffffff', GradientType=1);
	display: none;
	@include breakpoint($min480) {
		display: block;
	}
	.logo {
		padding: 10px 0;
	}

	.info-box li {

		font-size: .8rem;
		color: #666;
		font-weight: 300;
		strong {
			color: #666;
			font-size: 1.2rem;
			font-weight: 500;
		}
	}
	.icon-box {
		line-height: 1;
		font-size: 1.8rem;
		@include breakpoint($min576) {
			font-size: 2.2rem;
		}
		@include breakpoint($min768) {
			font-size: 3rem;
		}
		color: $accent_color;

		border-radius: 50%;
		transition: all 300ms ease;
	}
	.info-box-1 {
		@include breakpoint($min992) {
			margin-right: 30px;
		}
	}
}

/**
------------------------------------------------------------------------------------------------------------------------
header-lower
------------------------------------------------------------------------------------------------------------------------
 */
.header-lower {
	background-color: $blue_c;
}

.main-menu {
	.dropdown-menu{

	}
	#primary-menu{
		position: relative;
		z-index: 50;

	.nav-link {
		color: #fff;
		padding: 1rem 2rem;
font-size: 1rem;
		@include breakpoint($min992) {
			padding-right: .5rem;
			padding-left: .5rem;
			font-size:.8rem;
		}
		@include breakpoint($min1200) {
			padding-right: 1rem;
			padding-left: 1rem;
			font-size: 1rem;
		}
		&:hover,&:focus{
			background-color: darken($blue_c,8%);
		}
		&.dropdown-item {
			padding: .6rem 1rem;
			color: $primary_text_color;
			&:hover, &:focus {
				background-color: $accent_color;
				color: #fff;
			}
		}
	}}
	.navbar-toggler {
		color: #fff;
		font-size: 1.4rem;
	}
	&.navbar {
		padding: 0;
	}
	.dropdown-menu {
		border-radius: 0;
		.nav-link {
			padding: .3rem 1rem;
		}
		@include breakpoint($min992) {

			width: 300px;
			.nav-link.dropdown-item {
				border-bottom: 1px solid #d8d8d8;
				white-space: normal;
				&:last-child{
					border-bottom: none;
				}
			}
		}

	}
}
