.ftlogo{
	.logo-compose-img {
		width: 60px;
		height: 60px;
	}
	.logo-compose-text {
		font-size: 2rem;
	}
	display: block;
	margin-bottom: 20px;
	&:hover{
		text-decoration: none;
	}
}
.footer-about{
	color: #999;
}
.quickcontact-strip {
	background: $accent_color;

	padding: 40px 20px;
	margin-bottom: -55px;
	position: relative;
	height: 107px;
	z-index: 2;
	&:before {
		position: absolute;
		top: -41px;
		left: -39px;
		content: "";
		border: 40px solid transparent;
		border-bottom-color: $accent_color;
		width: 0;
		height: 0;
		border-bottom-width: 108px;
	}
	&:after {
		position: absolute;
		top: -41px;
		right: -39px;
		content: "";
		border: 40px solid transparent;
		border-bottom-color: $accent_color;
		width: 0;
		height: 0;
		border-bottom-width: 108px;
	}
	.text {
		font-size: 28px;
		color: #fff;
		font-weight: bold;
	}
	.btn-style-one {
		font-size: 17px;
	}
}

.site-footer {
	background: $dark_blue;
	padding: 90px 0 0px;
	.title {
		color: #fff;
		margin-bottom: 30px;
		position: relative;
		padding-bottom: 15px;
		margin-top: 15px;
		font-size: 20px;
		font-weight: 400;
		&:after {
			background-color: $accent_color;
			border-radius: 2px;
			bottom: 0;
			content: "";
			height: 3px;
			left: 0;
			position: absolute;
			width: 35px;
		}
	}
}

.footer-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		line-height: 30px;
		margin-top: 5px;
		i {
			margin-right: 5px;
			color: #ddd;
			padding-right: 7px;
		}
		a {
			color: #999;
			font-size: 1rem;
			&:hover {
				color: $accent_color;
			}
		}
	}
}

.footer-contact-info-list {
	padding: 0;
	margin: 0;
	li{
		margin-bottom: 15px;
	}
	a{
		color: $accent_color;
	}
	.fa {
		color: #ddd;
		padding-right: 14px;
		font-size: 20px;
	}
	.continfo {
		color: #6c6c6c;
		line-height: 24px;

		strong {
			color: #999;
			font-size: 16px;
		}
	}
}



.site-info {
	border-top: 1px solid #333;
	color: #999;
	margin-top: 60px;
	padding: 30px 0;
	background: #0b2028;
}

.signature {
	color: #999;
	.apaone {
		background-image: url(https://csrapatin.rs/wp-content/themes/csrapatin/img/apaone-logo.png);
		background-repeat: no-repeat;
		padding-left: 20px;
		background-position: 0;
		text-decoration: none;
		color: $accent_color;
	}
}