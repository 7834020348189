.home-news-slide {
	.slide-image {
		position: relative;
	}
	.slide-header {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 10px 20px;

		background-color: rgba($red_c, .9);
		.slide-title {
			font-size: 1.4rem;
			line-height: 1.1;
			margin: 0;
			a {
				color: #fff;
			}

		}
	}
	.slide-text-part {
		padding: 10px;
		background-color: #fff;
		.slide-excerpt {

		}
	}
}

.widget_search {
	input.search-field {
		height: 42px;
		border-color: #34495e;
		display: table-cell;
		position: relative;
		z-index: 2;
		float: left;
		width: 100%;
		margin-bottom: 0;
		padding: 6px 12px;
		background-color: #fff;
		background-image: none;
		border-radius: 0;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}
}

.banner_src {
	margin-bottom: 20px;
}
.widget_search_custom{
	margin-bottom: 20px;
}
.search .btn-search {
	padding: 10px 14px;
	background-color: $blue_c;
	border-radius: 0;
	color: #fff;
}

.search .form-control {
	height: 46px;
	border-color: $blue_c;
	border-radius: 0;
}
.widget_aside_menu{
	margin-bottom: 20px;
}
.sidebar-menu-nav {

	padding: 0;
	margin: 0;
	li {
		padding: 0;
		margin: 0;
		list-style: none;
		border-bottom: 2px solid transparent;
		a {
			border-left: 5px solid $red_c;
			padding-left: 20px;
			display: block;
			background-color: $blue_c;
			color: #fff;
			line-height: 50px;
			&:hover {
				text-decoration: none;
				background-color: $red_c;
			}
		}
	}
}