.page-box {
	margin-bottom: 50px;
	background-color: #fff;
	position: relative;
	&.page-box-half {
		height: 360px;

	}
}

.page-box-header {
	background-color: $blue_c;

}

.page-box-body {
	padding: 15px;
	background-color: #fff;
}

.page-box-title {
	color: #fff;
	padding: 8px 20px;
	font-weight: 700;
	font-size: 1.3rem;
	text-transform: uppercase;
	margin: 0;
}

.javna-nabavka-prev-front {
	border-bottom: 1px solid #dedede;
	padding-top: 15px;
}
.half__prev{
	.date {
		font-size: .8rem;
		font-weight: 700;
	}
}
.half__prev-link-item {

	color: $text_c;

	&:hover {
		text-decoration: none;
		color: $red_c;
	}
}

.page-box-footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	a {
		background-color: lighten($blue_c, 20%);
		color: #fff;
		font-weight: 700;
		display: block;
		padding: 6px 15px;
		&:hover {
			text-decoration: none;
			background-color: $blue_c;
		}
	}
}

/*
news-prev-box
 */
.news-prev-box__title {
	font-size: 1.2rem;
	line-height: 1.1;
	font-weight: 700;
	a {
		color: $blue_c;

	}
}

.news-prev-box__image {
	position: relative;
	.date {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		color: #fff;
		background-color: $red_c;
		padding-left: 10px;
	}
}

.news-prev-box {
	margin-bottom: 20px;
	.media-body {
		position: relative;
		.read-more-btn {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

.half__prev {
	&.obavestenje-single {
		border-bottom: 1px solid #dedede;
		padding-top: 15px;
	}
}