.javne_nabavke-prev {
	border-bottom: 1px solid #dedede;
	padding-top: 15px;
	margin-bottom: 30px;
	.date-objava {
		display: inline-block;
		padding: 3px 10px;
		background-color: $blue_c;
		color: #fff;
		font-size: .9rem;
		line-height: 1.8rem;

	}
	.date-rok {
		padding: 3px 10px;
		background-color: $red_c;
		color: #fff;
		line-height: 1.8rem;
		.text {
			font-size: .9rem;
		}
	}
	.prev-entry-title{
		font-weight: 300;
		padding: 15px 0;
		font-size: 1.4rem;
		margin-bottom: 30px;
	}
}
.document-list-title{
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 20px;
}
.dokumenti_lista{
	list-style: none;
	margin: 0;
	padding: 0;
	li{
		margin: 0;
		padding: 0;
	}
	.doc-link{
		margin-bottom: 15px;
		color: $text_c;
		.doc-icon{
			color: $red_c;
		}
	}
}
.prev-entry-title {
	font-size: 1.4rem;
	margin-bottom: 0;
}

.javne_nabavke-prev-link {
	display: block;

	color: $text_c;
	.prev-entry-header {
		padding: 10px;
		background-color: #f9f9f9;

	}
	&:hover {
		text-decoration: none;
		.prev-entry-header {
			background-color: #efefef;
			color: $red_c;
		}
	}
}