.wpcf7{

}

.wpcf7-form{
	label{
		font-weight: 700;
	}
	.required-mark{
		font-weight: 700;
		color: $red_c;
	}
	.form-desc{
		color: lighten($text_c,50%);
	}
	.form-control{
		border-radius: 0;
	}
	.wpcf7-submit{

	}
}