.wysiwyg-content{
	img.alignright{
		float: right;
		padding-left: 30px;
		padding-bottom: 15px;
	}
	img.size-large{
		max-width: 50%;
		height: auto;
	}
}