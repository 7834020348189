@charset "UTF-8";
/***
====================================================================
	Fonts
====================================================================
 ***/
/***
====================================================================
	Colors
====================================================================
 ***/
/***
====================================================================
	transition
====================================================================
 ***/
/***
====================================================================
	clearfix
====================================================================
 ***/
.clearfix:after {
  content: "";
  display: table;
  clear: both; }

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
.spacer10 {
  height: 10px; }

.spacer20 {
  height: 20px; }

.spacer30 {
  height: 30px; }

.spacer40 {
  height: 40px; }

.spacer50 {
  height: 50px; }

.spacer60 {
  height: 60px; }

.spacer70 {
  height: 70px; }

.spacer80 {
  height: 80px; }

.spacer90 {
  height: 90px; }

.spacer100 {
  height: 100px; }

/***
====================================================================
	media queries
====================================================================
 ***/
/*
media query example

@include breakpoint($min768) {
		position: fixed;
	}
*/
/***
====================================================================
	Mixins for Rem Font Sizing
====================================================================
 ***/
/*
@include font-size(16); -- 16px 1.6rem
*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("https://csrapatin.rs/wp-content/themes/csrapatin/img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/slick.eot");
  src: url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/slick.eot?#iefix") format("embedded-opentype"), url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/slick.woff") format("woff"), url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/slick.ttf") format("truetype"), url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

@font-face {
  font-family: "Flaticon";
  src: url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/flaticon.eot");
  src: url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/flaticond41d.eot") format("embedded-opentype"), url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/flaticon.woff") format("woff"), url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/flaticon.ttf") format("truetype"), url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/flaticon.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("https://csrapatin.rs/wp-content/themes/csrapatin/fonts/flaticon.svg") format("svg"); } }

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal; }

.flaticon-add:before {
  content: "\f100"; }

.flaticon-add-1:before {
  content: "\f101"; }

.flaticon-add-circular-button:before {
  content: "\f102"; }

.flaticon-alarm-bell:before {
  content: "\f103"; }

.flaticon-arrows:before {
  content: "\f104"; }

.flaticon-arrows-1:before {
  content: "\f105"; }

.flaticon-arrows-10:before {
  content: "\f106"; }

.flaticon-arrows-11:before {
  content: "\f107"; }

.flaticon-arrows-12:before {
  content: "\f108"; }

.flaticon-arrows-13:before {
  content: "\f109"; }

.flaticon-arrows-14:before {
  content: "\f10a"; }

.flaticon-arrows-15:before {
  content: "\f10b"; }

.flaticon-arrows-16:before {
  content: "\f10c"; }

.flaticon-arrows-17:before {
  content: "\f10d"; }

.flaticon-arrows-18:before {
  content: "\f10e"; }

.flaticon-arrows-19:before {
  content: "\f10f"; }

.flaticon-arrows-2:before {
  content: "\f110"; }

.flaticon-arrows-20:before {
  content: "\f111"; }

.flaticon-arrows-3:before {
  content: "\f112"; }

.flaticon-arrows-4:before {
  content: "\f113"; }

.flaticon-arrows-5:before {
  content: "\f114"; }

.flaticon-arrows-6:before {
  content: "\f115"; }

.flaticon-arrows-7:before {
  content: "\f116"; }

.flaticon-arrows-8:before {
  content: "\f117"; }

.flaticon-arrows-9:before {
  content: "\f118"; }

.flaticon-avatar:before {
  content: "\f119"; }

.flaticon-back:before {
  content: "\f11a"; }

.flaticon-back-1:before {
  content: "\f11b"; }

.flaticon-back-2:before {
  content: "\f11c"; }

.flaticon-badge:before {
  content: "\f11d"; }

.flaticon-bar-chart:before {
  content: "\f11e"; }

.flaticon-bar-chart-1:before {
  content: "\f11f"; }

.flaticon-barrel:before {
  content: "\f120"; }

.flaticon-battery:before {
  content: "\f121"; }

.flaticon-bell:before {
  content: "\f122"; }

.flaticon-blocks-with-angled-cuts:before {
  content: "\f123"; }

.flaticon-box:before {
  content: "\f124"; }

.flaticon-briefcase:before {
  content: "\f125"; }

.flaticon-bright-lightbulb:before {
  content: "\f126"; }

.flaticon-building:before {
  content: "\f127"; }

.flaticon-bullhorn:before {
  content: "\f128"; }

.flaticon-business:before {
  content: "\f129"; }

.flaticon-calendar:before {
  content: "\f12a"; }

.flaticon-calendar-1:before {
  content: "\f12b"; }

.flaticon-car:before {
  content: "\f12c"; }

.flaticon-car-1:before {
  content: "\f12d"; }

.flaticon-car-with-wrench:before {
  content: "\f12e"; }

.flaticon-cell-phone:before {
  content: "\f12f"; }

.flaticon-cellphone:before {
  content: "\f130"; }

.flaticon-chat:before {
  content: "\f131"; }

.flaticon-chat-1:before {
  content: "\f132"; }

.flaticon-check:before {
  content: "\f133"; }

.flaticon-check-1:before {
  content: "\f134"; }

.flaticon-check-2:before {
  content: "\f135"; }

.flaticon-check-symbol:before {
  content: "\f136"; }

.flaticon-checked:before {
  content: "\f137"; }

.flaticon-checked-1:before {
  content: "\f138"; }

.flaticon-circle:before {
  content: "\f139"; }

.flaticon-circle-1:before {
  content: "\f13a"; }

.flaticon-circle-2:before {
  content: "\f13b"; }

.flaticon-circle-3:before {
  content: "\f13c"; }

.flaticon-circle-4:before {
  content: "\f13d"; }

.flaticon-circular-speech-bubble-with-three-dots-inside:before {
  content: "\f13e"; }

.flaticon-clock:before {
  content: "\f13f"; }

.flaticon-clock-1:before {
  content: "\f140"; }

.flaticon-clock-2:before {
  content: "\f141"; }

.flaticon-clock-3:before {
  content: "\f142"; }

.flaticon-clock-4:before {
  content: "\f143"; }

.flaticon-close:before {
  content: "\f144"; }

.flaticon-close-1:before {
  content: "\f145"; }

.flaticon-close-2:before {
  content: "\f146"; }

.flaticon-cogwheel:before {
  content: "\f147"; }

.flaticon-cogwheel-1:before {
  content: "\f148"; }

.flaticon-coin:before {
  content: "\f149"; }

.flaticon-commerce:before {
  content: "\f14a"; }

.flaticon-computer:before {
  content: "\f14b"; }

.flaticon-connection:before {
  content: "\f14c"; }

.flaticon-corn:before {
  content: "\f14d"; }

.flaticon-correct:before {
  content: "\f14e"; }

.flaticon-cross:before {
  content: "\f14f"; }

.flaticon-cross-1:before {
  content: "\f150"; }

.flaticon-cross-2:before {
  content: "\f151"; }

.flaticon-cup:before {
  content: "\f152"; }

.flaticon-cursor:before {
  content: "\f153"; }

.flaticon-delete:before {
  content: "\f154"; }

.flaticon-detergent:before {
  content: "\f155"; }

.flaticon-e-mail-envelope:before {
  content: "\f156"; }

.flaticon-energy:before {
  content: "\f157"; }

.flaticon-envelope:before {
  content: "\f158"; }

.flaticon-envelope-1:before {
  content: "\f159"; }

.flaticon-expand:before {
  content: "\f15a"; }

.flaticon-expand-arrows:before {
  content: "\f15b"; }

.flaticon-expand-view:before {
  content: "\f15c"; }

.flaticon-eye:before {
  content: "\f15d"; }

.flaticon-eye-1:before {
  content: "\f15e"; }

.flaticon-eye-2:before {
  content: "\f15f"; }

.flaticon-factory:before {
  content: "\f160"; }

.flaticon-factory-1:before {
  content: "\f161"; }

.flaticon-five-stars-outlines:before {
  content: "\f162"; }

.flaticon-food:before {
  content: "\f163"; }

.flaticon-food-1:before {
  content: "\f164"; }

.flaticon-garbage:before {
  content: "\f165"; }

.flaticon-gasoline-pump:before {
  content: "\f166"; }

.flaticon-gear-wheel-with-cogs:before {
  content: "\f167"; }

.flaticon-gps:before {
  content: "\f168"; }

.flaticon-grain:before {
  content: "\f169"; }

.flaticon-happiness:before {
  content: "\f16a"; }

.flaticon-headphones:before {
  content: "\f16b"; }

.flaticon-heart:before {
  content: "\f16c"; }

.flaticon-heart-1:before {
  content: "\f16d"; }

.flaticon-home:before {
  content: "\f16e"; }

.flaticon-home-1:before {
  content: "\f16f"; }

.flaticon-home-2:before {
  content: "\f170"; }

.flaticon-home-3:before {
  content: "\f171"; }

.flaticon-home-location-marker:before {
  content: "\f172"; }

.flaticon-idea:before {
  content: "\f173"; }

.flaticon-interface:before {
  content: "\f174"; }

.flaticon-interface-1:before {
  content: "\f175"; }

.flaticon-interface-10:before {
  content: "\f176"; }

.flaticon-interface-11:before {
  content: "\f177"; }

.flaticon-interface-12:before {
  content: "\f178"; }

.flaticon-interface-13:before {
  content: "\f179"; }

.flaticon-interface-14:before {
  content: "\f17a"; }

.flaticon-interface-2:before {
  content: "\f17b"; }

.flaticon-interface-3:before {
  content: "\f17c"; }

.flaticon-interface-4:before {
  content: "\f17d"; }

.flaticon-interface-5:before {
  content: "\f17e"; }

.flaticon-interface-6:before {
  content: "\f17f"; }

.flaticon-interface-7:before {
  content: "\f180"; }

.flaticon-interface-8:before {
  content: "\f181"; }

.flaticon-interface-9:before {
  content: "\f182"; }

.flaticon-letter:before {
  content: "\f183"; }

.flaticon-letter-1:before {
  content: "\f184"; }

.flaticon-light-bulb-on:before {
  content: "\f185"; }

.flaticon-like:before {
  content: "\f186"; }

.flaticon-line:before {
  content: "\f187"; }

.flaticon-line-1:before {
  content: "\f188"; }

.flaticon-line-2:before {
  content: "\f189"; }

.flaticon-line-3:before {
  content: "\f18a"; }

.flaticon-link:before {
  content: "\f18b"; }

.flaticon-link-1:before {
  content: "\f18c"; }

.flaticon-link-2:before {
  content: "\f18d"; }

.flaticon-location:before {
  content: "\f18e"; }

.flaticon-location-marker:before {
  content: "\f18f"; }

.flaticon-lock:before {
  content: "\f190"; }

.flaticon-login:before {
  content: "\f191"; }

.flaticon-login-1:before {
  content: "\f192"; }

.flaticon-long-arrow-pointing-to-left:before {
  content: "\f193"; }

.flaticon-mail:before {
  content: "\f194"; }

.flaticon-mail-1:before {
  content: "\f195"; }

.flaticon-map:before {
  content: "\f196"; }

.flaticon-maximize:before {
  content: "\f197"; }

.flaticon-mechanic-tool:before {
  content: "\f198"; }

.flaticon-medal:before {
  content: "\f199"; }

.flaticon-medal-1:before {
  content: "\f19a"; }

.flaticon-medal-2:before {
  content: "\f19b"; }

.flaticon-microphone:before {
  content: "\f19c"; }

.flaticon-more:before {
  content: "\f19d"; }

.flaticon-multimedia:before {
  content: "\f19e"; }

.flaticon-multimedia-1:before {
  content: "\f19f"; }

.flaticon-multimedia-2:before {
  content: "\f1a0"; }

.flaticon-multimedia-3:before {
  content: "\f1a1"; }

.flaticon-multimedia-4:before {
  content: "\f1a2"; }

.flaticon-music:before {
  content: "\f1a3"; }

.flaticon-nature:before {
  content: "\f1a4"; }

.flaticon-networking:before {
  content: "\f1a5"; }

.flaticon-next:before {
  content: "\f1a6"; }

.flaticon-next-1:before {
  content: "\f1a7"; }

.flaticon-note:before {
  content: "\f1a8"; }

.flaticon-office-worker-outline:before {
  content: "\f1a9"; }

.flaticon-oil:before {
  content: "\f1aa"; }

.flaticon-padlock:before {
  content: "\f1ab"; }

.flaticon-padlock-1:before {
  content: "\f1ac"; }

.flaticon-padnote:before {
  content: "\f1ad"; }

.flaticon-pair-of-gears:before {
  content: "\f1ae"; }

.flaticon-paper-plane:before {
  content: "\f1af"; }

.flaticon-paper-plane-1:before {
  content: "\f1b0"; }

.flaticon-people:before {
  content: "\f1b1"; }

.flaticon-phone-call:before {
  content: "\f1b2"; }

.flaticon-picture:before {
  content: "\f1b3"; }

.flaticon-piston:before {
  content: "\f1b4"; }

.flaticon-placeholder:before {
  content: "\f1b5"; }

.flaticon-placeholder-1:before {
  content: "\f1b6"; }

.flaticon-placeholder-2:before {
  content: "\f1b7"; }

.flaticon-placeholder-3:before {
  content: "\f1b8"; }

.flaticon-play-button:before {
  content: "\f1b9"; }

.flaticon-plug:before {
  content: "\f1ba"; }

.flaticon-plug-1:before {
  content: "\f1bb"; }

.flaticon-power:before {
  content: "\f1bc"; }

.flaticon-prize-badge-with-star-and-ribbon:before {
  content: "\f1bd"; }

.flaticon-quality:before {
  content: "\f1be"; }

.flaticon-quality-badge:before {
  content: "\f1bf"; }

.flaticon-right-arrow:before {
  content: "\f1c0"; }

.flaticon-round:before {
  content: "\f1c1"; }

.flaticon-science:before {
  content: "\f1c2"; }

.flaticon-screwdriver:before {
  content: "\f1c3"; }

.flaticon-search:before {
  content: "\f1c4"; }

.flaticon-search-1:before {
  content: "\f1c5"; }

.flaticon-security-badge:before {
  content: "\f1c6"; }

.flaticon-send:before {
  content: "\f1c7"; }

.flaticon-send-symbol:before {
  content: "\f1c8"; }

.flaticon-shape:before {
  content: "\f1c9"; }

.flaticon-shapes:before {
  content: "\f1ca"; }

.flaticon-shapes-1:before {
  content: "\f1cb"; }

.flaticon-shield:before {
  content: "\f1cc"; }

.flaticon-sign:before {
  content: "\f1cd"; }

.flaticon-smartphone:before {
  content: "\f1ce"; }

.flaticon-smartphone-1:before {
  content: "\f1cf"; }

.flaticon-smartphone-call:before {
  content: "\f1d0"; }

.flaticon-smiling-emoticon-square-face:before {
  content: "\f1d1"; }

.flaticon-speech-bubble:before {
  content: "\f1d2"; }

.flaticon-speech-bubble-1:before {
  content: "\f1d3"; }

.flaticon-speech-bubble-outline-of-rectangular-shape:before {
  content: "\f1d4"; }

.flaticon-speech-bubble-with-three-circles-inside:before {
  content: "\f1d5"; }

.flaticon-spin:before {
  content: "\f1d6"; }

.flaticon-sport-trophy:before {
  content: "\f1d7"; }

.flaticon-stats:before {
  content: "\f1d8"; }

.flaticon-stick-man:before {
  content: "\f1d9"; }

.flaticon-straight-quotes:before {
  content: "\f1da"; }

.flaticon-success:before {
  content: "\f1db"; }

.flaticon-support:before {
  content: "\f1dc"; }

.flaticon-symbol:before {
  content: "\f1dd"; }

.flaticon-tag:before {
  content: "\f1de"; }

.flaticon-technology:before {
  content: "\f1df"; }

.flaticon-technology-1:before {
  content: "\f1e0"; }

.flaticon-technology-2:before {
  content: "\f1e1"; }

.flaticon-technology-3:before {
  content: "\f1e2"; }

.flaticon-telephone:before {
  content: "\f1e3"; }

.flaticon-telephone-1:before {
  content: "\f1e4"; }

.flaticon-test-tube:before {
  content: "\f1e5"; }

.flaticon-three:before {
  content: "\f1e6"; }

.flaticon-tick-inside-circle:before {
  content: "\f1e7"; }

.flaticon-time:before {
  content: "\f1e8"; }

.flaticon-time-1:before {
  content: "\f1e9"; }

.flaticon-tool:before {
  content: "\f1ea"; }

.flaticon-tractor:before {
  content: "\f1eb"; }

.flaticon-tractor-front:before {
  content: "\f1ec"; }

.flaticon-tractor-side-view:before {
  content: "\f1ed"; }

.flaticon-tractor-side-view-1:before {
  content: "\f1ee"; }

.flaticon-transport:before {
  content: "\f1ef"; }

.flaticon-trolley-push-cart-with-boxes:before {
  content: "\f1f0"; }

.flaticon-trophy:before {
  content: "\f1f1"; }

.flaticon-trophy-1:before {
  content: "\f1f2"; }

.flaticon-trophy-2:before {
  content: "\f1f3"; }

.flaticon-unlink:before {
  content: "\f1f4"; }

.flaticon-up-arrow:before {
  content: "\f1f5"; }

.flaticon-upload:before {
  content: "\f1f6"; }

.flaticon-user:before {
  content: "\f1f7"; }

.flaticon-visible:before {
  content: "\f1f8"; }

.flaticon-wallet:before {
  content: "\f1f9"; }

.flaticon-wallet-1:before {
  content: "\f1fa"; }

.flaticon-web:before {
  content: "\f1fb"; }

.flaticon-web-1:before {
  content: "\f1fc"; }

.flaticon-web-development:before {
  content: "\f1fd"; }

.flaticon-worker:before {
  content: "\f1fe"; }

.flaticon-money-1:before {
  content: "\f100"; }

.flaticon-avatar:before {
  content: "\f101"; }

.flaticon-open-book:before {
  content: "\f102"; }

.flaticon-chart:before {
  content: "\f103"; }

.flaticon-atomic:before {
  content: "\f104"; }

.flaticon-time:before {
  content: "\f105"; }

.flaticon-money:before {
  content: "\f106"; }

.flaticon-support:before {
  content: "\f107"; }

.flaticon-get-money:before {
  content: "\f108"; }

.flaticon-piggy-bank:before {
  content: "\f109"; }

.flaticon-rich:before {
  content: "\f10a"; }

.flaticon-notes:before {
  content: "\f10b"; }

.flaticon-graph:before {
  content: "\f10c"; }

html {
  min-height: 100%; }

body {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-weight: 400;
  background-color: #dedede;
  background-image: url("https://csrapatin.rs/wp-content/themes/csrapatin/img/page-bg-grb-15.png");
  background-size: cover;
  background-attachment: fixed; }
  body img {
    max-width: 100%;
    display: block; }

#map {
  height: 400px;
  width: 100%; }

.contact-info-map {
  font-size: 14px; }
  .contact-info-map strong {
    font-weight: 700;
    display: block;
    margin-bottom: 5px;
    font-size: 16px; }

.grey-bg {
  background: #f5f5f5; }

/**
------------------------------------------------------------------------------------------------------------------------
logo-compose
------------------------------------------------------------------------------------------------------------------------
 */
.logo-compose-link:hover, .logo-compose-link:focus {
  text-decoration: none; }

.logo-compose-text .first {
  font-weight: 700;
  color: #fff; }

.logo-compose-text .last {
  font-weight: 300;
  color: #fff; }

.logo-compose-img {
  width: 100px;
  height: 100px; }

.logo-compose-text {
  font-size: 3rem; }

.navbar-brand .logo-compose-img {
  width: 40px;
  height: 40px; }

.navbar-brand .logo-compose-text {
  font-size: 1.4rem; }
  @media (min-width: 768px) {
    .navbar-brand .logo-compose-text {
      font-size: 2rem; } }
  .navbar-brand .logo-compose-text .first {
    color: #fff; }
  .navbar-brand .logo-compose-text .last {
    color: #d1d1d1; }

/**
------------------------------------------------------------------------------------------------------------------------
decor-line
------------------------------------------------------------------------------------------------------------------------
 */
.decor-line {
  margin-top: 10px;
  display: inline-block; }
  .decor-line:after, .decor-line:before {
    content: '';
    width: 40px;
    background: #c52f38;
    height: 2px;
    display: inline-block;
    vertical-align: middle; }
  .decor-line .decor-line-inner {
    width: 26px;
    height: 16px;
    background-image: url(https://csrapatin.rs/wp-content/themes/csrapatin/img/csrapatin-Logo-image.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    vertical-align: middle; }

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */ }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0; }

.main-header {
  font-family: 'Roboto', sans-serif;
  transition: all 500ms ease; }

.main-header .header-top {
  position: relative;
  font-size: 13px;
  padding: 9px 0px 7px;
  background: #2c2e35;
  color: #ffffff;
  font-family: 'Roboto', sans-serif; }
  @media (min-width: 480px) {
    .main-header .header-top {
      background: #c52f38; } }

.main-header .header-top a {
  color: #ffffff;
  transition: all 300ms ease; }

.main-header .top-right .links-nav {
  margin-right: 0px;
  margin-left: 25px; }

.main-header .links-nav a {
  position: relative;
  line-height: 26px;
  font-size: 13px;
  text-transform: capitalize; }
  .main-header .links-nav a.active {
    color: #c52f38; }
    @media (min-width: 480px) {
      .main-header .links-nav a.active {
        color: #333; } }

.main-header .links-nav .fa {
  padding-right: 8px;
  font-size: 16px;
  color: #fff; }

.main-header .header-top .social-links a {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  font-size: 13px;
  font-weight: normal;
  color: #ffffff;
  line-height: 26px; }

.main-header .header-top a:hover {
  color: #333; }

.header-upper__logo-wrap {
  position: relative;
  height: 100px; }

.header-logo {
  position: absolute;
  top: -50px;
  left: 0; }
  .header-logo .logo-compose-img {
    width: 180px;
    height: 180px; }

/**
------------------------------------------------------------------------------------------------------------------------
language-chooser
------------------------------------------------------------------------------------------------------------------------
 */
#alphabet-chooser.cir a:first-child {
  color: #c52f38; }

#alphabet-chooser.lat a:last-child {
  color: #c52f38; }

/**
------------------------------------------------------------------------------------------------------------------------
header-top
------------------------------------------------------------------------------------------------------------------------
 */
.header-top .phone-wrap {
  position: relative;
  z-index: 50; }
  @media (min-width: 992px) {
    .header-top .phone-wrap {
      margin-left: 180px; } }

.header-top__phone, .header-top__work-hours {
  display: none; }
  @media (min-width: 480px) {
    .header-top__phone, .header-top__work-hours {
      display: inline-block; } }

/**
------------------------------------------------------------------------------------------------------------------------
header-upper
------------------------------------------------------------------------------------------------------------------------
 */
.header-upper {
  background-color: #0f539a;
  background: -moz-linear-gradient(left, #0f539a 25%, rgba(255, 255, 255, 0) 58%);
  background: -webkit-linear-gradient(left, #0f539a 25%, rgba(255, 255, 255, 0) 58%);
  background: linear-gradient(to right, #0f539a 25%, rgba(255, 255, 255, 0) 58%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f539a', endColorstr='#00ffffff', GradientType=1);
  display: none; }
  @media (min-width: 480px) {
    .header-upper {
      display: block; } }
  .header-upper .logo {
    padding: 10px 0; }
  .header-upper .info-box li {
    font-size: .8rem;
    color: #666;
    font-weight: 300; }
    .header-upper .info-box li strong {
      color: #666;
      font-size: 1.2rem;
      font-weight: 500; }
  .header-upper .icon-box {
    line-height: 1;
    font-size: 1.8rem;
    color: #c52f38;
    border-radius: 50%;
    transition: all 300ms ease; }
    @media (min-width: 576px) {
      .header-upper .icon-box {
        font-size: 2.2rem; } }
    @media (min-width: 768px) {
      .header-upper .icon-box {
        font-size: 3rem; } }
  @media (min-width: 992px) {
    .header-upper .info-box-1 {
      margin-right: 30px; } }

/**
------------------------------------------------------------------------------------------------------------------------
header-lower
------------------------------------------------------------------------------------------------------------------------
 */
.header-lower {
  background-color: #0d396a; }

.main-menu #primary-menu {
  position: relative;
  z-index: 50; }
  .main-menu #primary-menu .nav-link {
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1rem; }
    @media (min-width: 992px) {
      .main-menu #primary-menu .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
        font-size: .8rem; } }
    @media (min-width: 1200px) {
      .main-menu #primary-menu .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 1rem; } }
    .main-menu #primary-menu .nav-link:hover, .main-menu #primary-menu .nav-link:focus {
      background-color: #092546; }
    .main-menu #primary-menu .nav-link.dropdown-item {
      padding: .6rem 1rem;
      color: #212121; }
      .main-menu #primary-menu .nav-link.dropdown-item:hover, .main-menu #primary-menu .nav-link.dropdown-item:focus {
        background-color: #c52f38;
        color: #fff; }

.main-menu .navbar-toggler {
  color: #fff;
  font-size: 1.4rem; }

.main-menu.navbar {
  padding: 0; }

.main-menu .dropdown-menu {
  border-radius: 0; }
  .main-menu .dropdown-menu .nav-link {
    padding: .3rem 1rem; }
  @media (min-width: 992px) {
    .main-menu .dropdown-menu {
      width: 300px; }
      .main-menu .dropdown-menu .nav-link.dropdown-item {
        border-bottom: 1px solid #d8d8d8;
        white-space: normal; }
        .main-menu .dropdown-menu .nav-link.dropdown-item:last-child {
          border-bottom: none; } }

.ftlogo {
  display: block;
  margin-bottom: 20px; }
  .ftlogo .logo-compose-img {
    width: 60px;
    height: 60px; }
  .ftlogo .logo-compose-text {
    font-size: 2rem; }
  .ftlogo:hover {
    text-decoration: none; }

.footer-about {
  color: #999; }

.quickcontact-strip {
  background: #c52f38;
  padding: 40px 20px;
  margin-bottom: -55px;
  position: relative;
  height: 107px;
  z-index: 2; }
  .quickcontact-strip:before {
    position: absolute;
    top: -41px;
    left: -39px;
    content: "";
    border: 40px solid transparent;
    border-bottom-color: #c52f38;
    width: 0;
    height: 0;
    border-bottom-width: 108px; }
  .quickcontact-strip:after {
    position: absolute;
    top: -41px;
    right: -39px;
    content: "";
    border: 40px solid transparent;
    border-bottom-color: #c52f38;
    width: 0;
    height: 0;
    border-bottom-width: 108px; }
  .quickcontact-strip .text {
    font-size: 28px;
    color: #fff;
    font-weight: bold; }
  .quickcontact-strip .btn-style-one {
    font-size: 17px; }

.site-footer {
  background: #0b2028;
  padding: 90px 0 0px; }
  .site-footer .title {
    color: #fff;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 400; }
    .site-footer .title:after {
      background-color: #c52f38;
      border-radius: 2px;
      bottom: 0;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      width: 35px; }

.footer-menu {
  list-style: none;
  margin: 0;
  padding: 0; }
  .footer-menu li {
    line-height: 30px;
    margin-top: 5px; }
    .footer-menu li i {
      margin-right: 5px;
      color: #ddd;
      padding-right: 7px; }
    .footer-menu li a {
      color: #999;
      font-size: 1rem; }
      .footer-menu li a:hover {
        color: #c52f38; }

.footer-contact-info-list {
  padding: 0;
  margin: 0; }
  .footer-contact-info-list li {
    margin-bottom: 15px; }
  .footer-contact-info-list a {
    color: #c52f38; }
  .footer-contact-info-list .fa {
    color: #ddd;
    padding-right: 14px;
    font-size: 20px; }
  .footer-contact-info-list .continfo {
    color: #6c6c6c;
    line-height: 24px; }
    .footer-contact-info-list .continfo strong {
      color: #999;
      font-size: 16px; }

.site-info {
  border-top: 1px solid #333;
  color: #999;
  margin-top: 60px;
  padding: 30px 0;
  background: #0b2028; }

.signature {
  color: #999; }
  .signature .apaone {
    background-image: url(https://csrapatin.rs/wp-content/themes/csrapatin/img/apaone-logo.png);
    background-repeat: no-repeat;
    padding-left: 20px;
    background-position: 0;
    text-decoration: none;
    color: #c52f38; }

.entry-content-box {
  padding: 60px 40px 60px 40px; }
  .entry-content-box .entry-content-inner {
    padding-bottom: 60px; }
    .entry-content-box .entry-content-inner:last-child {
      padding-bottom: 0; }

.white-bg {
  background-color: #fff; }

.front-page__content-area {
  padding-top: 30px; }

.home-welcome {
  position: relative;
  background: #c52f38;
  text-align: center;
  z-index: 1300;
  padding: 20px 10px; }
  .home-welcome:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: #c52f38;
    transform-origin: bottom right;
    -ms-transform: skew(30deg, 0deg);
    -webkit-transform: skew(30deg, 0deg);
    transform: skew(30deg, 0deg); }
  .home-welcome:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #c52f38;
    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg); }
  .home-welcome .home-welcome-text {
    font-size: 1.8rem;
    font-weight: 300; }
  .home-welcome .home-welcome-title {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 700; }

/**
------------------------------------------------------------------------------------------------------------------------
sec-header
------------------------------------------------------------------------------------------------------------------------
 */
.sec-header {
  margin-bottom: 30px;
  position: relative;
  z-index: 10; }

/**
------------------------------------------------------------------------------------------------------------------------
home-counter-sec
------------------------------------------------------------------------------------------------------------------------
 */
.home-counter-sec {
  margin-top: 60px;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 0px 80px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url(http://csrapatin.rs/wp-content/themes/csrapatin/img/home-counter-bg-1.jpg); }
  .home-counter-sec:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(11, 32, 40, 0.9); }
  .home-counter-sec .sec-title {
    color: #fff; }

.counter-box {
  text-align: center;
  width: 160px;
  height: 160px;
  position: relative;
  margin: 0 auto; }
  .counter-box .icon-wrap {
    position: relative;
    z-index: 10;
    font-size: 62px;
    background: transparent;
    line-height: 1;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    margin-top: 30px; }
  .counter-box .text-wrap {
    padding-top: 10px;
    position: relative;
    z-index: 10; }
    .counter-box .text-wrap .timer {
      font-size: 1.8rem;
      color: #fff;
      font-weight: 700; }
    .counter-box .text-wrap p {
      color: #fff;
      font-weight: 700; }
  .counter-box .counter-box-bg {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 200px;
    background-color: #c52f38;
    width: 160px;
    height: 160px; }

.counter-box-title {
  margin-top: 12px;
  text-align: center;
  font-size: 1.2rem;
  color: #fff; }

/**
------------------------------------------------------------------------------------------------------------------------
home-news-section
------------------------------------------------------------------------------------------------------------------------
 */
.home-news-section {
  padding-top: 80px;
  padding-bottom: 80px; }

/**
------------------------------------------------------------------------------------------------------------------------
page-title
------------------------------------------------------------------------------------------------------------------------
 */
.page-title {
  position: relative;
  text-align: center;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  padding: 60px 0px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .page-title {
      padding: 130px 0px; } }
  .page-title:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }
  .page-title .entry-title {
    position: relative;
    z-index: 10;
    font-size: 32px;
    font-weight: 800;
    color: #fff;
    letter-spacing: 1px;
    line-height: 1em;
    margin-top: 8px;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    margin-bottom: 0; }
  .page-title .bread-crumb {
    position: relative;
    padding-top: 5px;
    text-align: center; }
    .page-title .bread-crumb > span {
      position: relative;
      display: inline-block;
      line-height: 30px;
      margin-left: 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize; }
      .page-title .bread-crumb > span a {
        color: #c52f38;
        transition: all 0.3s ease; }

.paddsec {
  padding: 80px 0; }

.home-news-slide .slide-image {
  position: relative; }

.home-news-slide .slide-header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(197, 47, 56, 0.9); }
  .home-news-slide .slide-header .slide-title {
    font-size: 1.4rem;
    line-height: 1.1;
    margin: 0; }
    .home-news-slide .slide-header .slide-title a {
      color: #fff; }

.home-news-slide .slide-text-part {
  padding: 10px;
  background-color: #fff; }

.widget_search input.search-field {
  height: 42px;
  border-color: #34495e;
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

.banner_src {
  margin-bottom: 20px; }

.widget_search_custom {
  margin-bottom: 20px; }

.search .btn-search {
  padding: 10px 14px;
  background-color: #0d396a;
  border-radius: 0;
  color: #fff; }

.search .form-control {
  height: 46px;
  border-color: #0d396a;
  border-radius: 0; }

.widget_aside_menu {
  margin-bottom: 20px; }

.sidebar-menu-nav {
  padding: 0;
  margin: 0; }
  .sidebar-menu-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 2px solid transparent; }
    .sidebar-menu-nav li a {
      border-left: 5px solid #c52f38;
      padding-left: 20px;
      display: block;
      background-color: #0d396a;
      color: #fff;
      line-height: 50px; }
      .sidebar-menu-nav li a:hover {
        text-decoration: none;
        background-color: #c52f38; }

.javne_nabavke-prev {
  border-bottom: 1px solid #dedede;
  padding-top: 15px;
  margin-bottom: 30px; }
  .javne_nabavke-prev .date-objava {
    display: inline-block;
    padding: 3px 10px;
    background-color: #0d396a;
    color: #fff;
    font-size: .9rem;
    line-height: 1.8rem; }
  .javne_nabavke-prev .date-rok {
    padding: 3px 10px;
    background-color: #c52f38;
    color: #fff;
    line-height: 1.8rem; }
    .javne_nabavke-prev .date-rok .text {
      font-size: .9rem; }
  .javne_nabavke-prev .prev-entry-title {
    font-weight: 300;
    padding: 15px 0;
    font-size: 1.4rem;
    margin-bottom: 30px; }

.document-list-title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 20px; }

.dokumenti_lista {
  list-style: none;
  margin: 0;
  padding: 0; }
  .dokumenti_lista li {
    margin: 0;
    padding: 0; }
  .dokumenti_lista .doc-link {
    margin-bottom: 15px;
    color: #333; }
    .dokumenti_lista .doc-link .doc-icon {
      color: #c52f38; }

.prev-entry-title {
  font-size: 1.4rem;
  margin-bottom: 0; }

.javne_nabavke-prev-link {
  display: block;
  color: #333; }
  .javne_nabavke-prev-link .prev-entry-header {
    padding: 10px;
    background-color: #f9f9f9; }
  .javne_nabavke-prev-link:hover {
    text-decoration: none; }
    .javne_nabavke-prev-link:hover .prev-entry-header {
      background-color: #efefef;
      color: #c52f38; }

.prev-entry-title-pu-cat {
  font-size: 1.4rem;
  margin-bottom: 10px; }

.prev-entry-title-pu {
  font-weight: 300;
  padding: 5px 0;
  font-size: 1.2rem;
  margin-bottom: 10px; }

.prava_usluge-prev {
  border-bottom: 1px solid #dedede;
  padding-top: 15px;
  margin-bottom: 5px; }

.obavestenja-prev {
  border-bottom: 1px solid #dedede;
  padding-top: 15px;
  margin-bottom: 30px; }

.entry-prev-title {
  font-size: 1.4rem; }
  .entry-prev-title a {
    color: #333;
    padding: 10px 0;
    display: block; }

.entry-prev {
  border-bottom: 1px solid #dedede;
  padding-top: 15px;
  margin-bottom: 30px; }
  .entry-prev img {
    height: auto;
    width: 100%; }
  .entry-prev .date-objava {
    color: #b3b3b3;
    margin-bottom: 10px; }

.entry-prev__excerpt {
  margin-bottom: 20px; }

.post-nav-image-link {
  display: block;
  margin-bottom: 5px; }

/**
post-nav
 */
.post-nav-item {
  background-color: #fff; }

.post-nav-link {
  display: block;
  background-color: #0d396a;
  color: #fff;
  padding: 5px 15px; }
  .post-nav-link.next {
    text-align: right; }
  .post-nav-link:hover {
    text-decoration: none;
    color: #c52f38; }

.post-nav-header {
  padding: 0 15px;
  height: 46px;
  overflow: hidden; }

.post-nav-title {
  font-size: 1.2rem;
  font-weight: 700; }
  .post-nav-title a {
    color: #333; }
    .post-nav-title a:hover {
      text-decoration: none;
      color: #c52f38; }

.post-nav-excerpt {
  padding: 10px 15px 30px 15px; }

.wpcf7-form label {
  font-weight: 700; }

.wpcf7-form .required-mark {
  font-weight: 700;
  color: #c52f38; }

.wpcf7-form .form-desc {
  color: #b3b3b3; }

.wpcf7-form .form-control {
  border-radius: 0; }

.wysiwyg-content img.alignright {
  float: right;
  padding-left: 30px;
  padding-bottom: 15px; }

.wysiwyg-content img.size-large {
  max-width: 50%;
  height: auto; }

.btn-style-one {
  position: relative;
  padding: 8px 30px;
  line-height: 24px;
  text-transform: uppercase;
  background: #0b2028;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #0b2028 !important;
  border-radius: 2px;
  font-family: 'Roboto', sans-serif; }
  .btn-style-one:hover {
    color: #0b2028 !important;
    border-color: #0b2028 !important;
    background: none; }

.read-more-btn {
  color: #fff;
  border-radius: 2px;
  background-color: #c52f38;
  border-color: #c52f38;
  font-size: 1rem;
  transition: all .5s ease; }
  .read-more-btn:hover {
    color: #333; }

.round-social-btn {
  display: flex;
  background: #0e2a33;
  border-radius: 50px;
  color: #cacfd3;
  padding: 0;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  transition: all 0.3s ease 0s; }
  .round-social-btn .fa {
    margin-right: 0;
    color: #fff;
    font-size: 1rem;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background-color: #c52f38;
    color: #fff;
    border-radius: 30px; }
  .round-social-btn .text {
    padding-right: 20px;
    padding-left: 15px; }
  .round-social-btn:hover, .round-social-btn:focus {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .round-social-btn:hover .text, .round-social-btn:focus .text {
      color: #c52f38; }

.blurslider-gradient {
  background: -webkit-linear-gradient(left, #FF0080, #FF8C00, #40E0D0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.blurslider-line {
  background: -webkit-linear-gradient(left, #FF0080, #FF8C00, #40E0D0) !important; }

.tp-bannertimer {
  height: 2px !important;
  background: #40E0D0;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0) !important;
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0) !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.btn-edit {
  color: #fff;
  border-radius: 2px;
  background-color: #c52f38;
  border-color: #c52f38;
  font-size: 1rem;
  transition: all .5s ease; }
  .btn-edit:before {
    content: '\f013';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    padding-right: 10px;
    text-rendering: auto; }
  .btn-edit:hover {
    color: #333; }

.btn-form {
  color: #fff;
  border-radius: 2px;
  background-color: #c52f38;
  border-color: #c52f38;
  font-size: 1rem;
  transition: all .5s ease; }
  .btn-form:before {
    content: '\f013';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    padding-right: 10px;
    text-rendering: auto; }
  .btn-form:hover {
    color: #333; }

.service-item .hover-link-wrap {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 20%;
  transition-duration: 0.3s;
  z-index: 9;
  transform: translate(-50%, -50%); }
  .service-item .hover-link-wrap i {
    color: #fff;
    display: block;
    font-size: 20px;
    padding: 0;
    background: #f5af24;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    font-size: 22px;
    line-height: 50px; }
  .service-item .hover-link-wrap .hover-link {
    text-align: center; }
    .service-item .hover-link-wrap .hover-link:hover {
      text-decoration: none; }
      .service-item .hover-link-wrap .hover-link:hover i {
        color: #333; }

.service-item p {
  margin-bottom: 20px;
  height: 72px;
  overflow: hidden; }

.service-item:hover .hover-link-wrap {
  opacity: 1;
  top: 50%; }

.service-item:hover .service-img:after {
  opacity: 1; }

.service-item-title {
  font-size: 1.4rem; }
  .service-item-title a {
    color: #333; }
    .service-item-title a:hover {
      color: #c52f38; }

.service-img {
  position: relative;
  overflow: hidden; }
  .service-img:after {
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-duration: 0.3s;
    width: 100%;
    background: rgba(2, 27, 39, 0.8); }

.service-item-title {
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 20px; }

.news-block {
  margin-bottom: 30px;
  transition: all .5s ease; }
  .news-block:hover {
    box-shadow: 0 0 10px 5px #ddd;
    margin-top: -5px; }
  .news-block .image {
    position: relative; }
    .news-block .image .post-date {
      position: absolute;
      right: 0px;
      top: 0px;
      font-size: 25px;
      color: #ffffff;
      font-weight: bold;
      padding: 5px 15px 5px 5px;
      display: inline-block;
      background-color: #f5af24;
      z-index: 1;
      text-transform: uppercase; }
      .news-block .image .post-date:before {
        content: "";
        width: 0;
        height: 0;
        border: 24px solid transparent;
        border-top-color: #f5af24;
        position: absolute;
        left: -24px;
        top: 0;
        z-index: -1;
        border-top-width: 52px; }
      .news-block .image .post-date span {
        font-size: 14px; }
  .news-block .lower-content {
    border: 1px solid #eee;
    padding: 20px 20px 20px;
    background: #fff; }
    .news-block .lower-content .news-block-title {
      height: 50px;
      overflow: hidden;
      margin-bottom: 10px;
      margin-top: 5px;
      font-size: 18px;
      line-height: 27px; }
      .news-block .lower-content .news-block-title a {
        color: #333; }
    .news-block .lower-content .text {
      margin-bottom: 12px; }
    .news-block .lower-content .metalink {
      border-top: 1px solid #eee;
      padding-top: 14px;
      margin-top: 20px; }
    .news-block .lower-content .detail {
      color: #c52f38;
      font-weight: 400; }

.page-box {
  margin-bottom: 50px;
  background-color: #fff;
  position: relative; }
  .page-box.page-box-half {
    height: 360px; }

.page-box-header {
  background-color: #0d396a; }

.page-box-body {
  padding: 15px;
  background-color: #fff; }

.page-box-title {
  color: #fff;
  padding: 8px 20px;
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin: 0; }

.javna-nabavka-prev-front {
  border-bottom: 1px solid #dedede;
  padding-top: 15px; }

.half__prev .date {
  font-size: .8rem;
  font-weight: 700; }

.half__prev-link-item {
  color: #333; }
  .half__prev-link-item:hover {
    text-decoration: none;
    color: #c52f38; }

.page-box-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0; }
  .page-box-footer a {
    background-color: #186ac5;
    color: #fff;
    font-weight: 700;
    display: block;
    padding: 6px 15px; }
    .page-box-footer a:hover {
      text-decoration: none;
      background-color: #0d396a; }

/*
news-prev-box
 */
.news-prev-box__title {
  font-size: 1.2rem;
  line-height: 1.1;
  font-weight: 700; }
  .news-prev-box__title a {
    color: #0d396a; }

.news-prev-box__image {
  position: relative; }
  .news-prev-box__image .date {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: #fff;
    background-color: #c52f38;
    padding-left: 10px; }

.news-prev-box {
  margin-bottom: 20px; }
  .news-prev-box .media-body {
    position: relative; }
    .news-prev-box .media-body .read-more-btn {
      position: absolute;
      right: 0;
      bottom: 0; }

.half__prev.obavestenje-single {
  border-bottom: 1px solid #dedede;
  padding-top: 15px; }
