.prev-entry-title-pu-cat{
	font-size: 1.4rem;
	margin-bottom: 10px;
}
.prev-entry-title-pu {
	font-weight: 300;
	padding: 5px 0;
	font-size: 1.2rem;
	margin-bottom: 10px;
}

.prava_usluge-prev {
	border-bottom: 1px solid #dedede;
	padding-top: 15px;
	margin-bottom: 5px;
}