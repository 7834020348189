.entry-content-box {
	padding: 60px 40px 60px 40px;

	.entry-content-inner{
		padding-bottom: 60px;
		&:last-child{
			padding-bottom: 0;
		}
	}
}

.white-bg {
	background-color: #fff;
}
.front-page__content-area{
	padding-top: 30px;
}
.home-welcome {
	position: relative;
	background: $accent_color;
	text-align: center;
	//margin-top: -26px;
	z-index: 1300;
	padding: 20px 10px;
	&:before {
		content: " ";
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		z-index: -1;
		background: $accent_color;
		transform-origin: bottom right;
		-ms-transform: skew(30deg, 0deg);
		-webkit-transform: skew(30deg, 0deg);
		transform: skew(30deg, 0deg);
	}
	&:after {
		content: " ";
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: $accent_color;
		transform-origin: bottom left;
		-ms-transform: skew(-30deg, 0deg);
		-webkit-transform: skew(-30deg, 0deg);
		transform: skew(-30deg, 0deg);
	}
	.home-welcome-text {
		font-size: 1.8rem;
		font-weight: 300;
	}
	.home-welcome-title {
		margin: 0;
		font-size: 2.2rem;
		font-weight: 700;
	}
}

/**
------------------------------------------------------------------------------------------------------------------------
sec-header
------------------------------------------------------------------------------------------------------------------------
 */
.sec-header {
	margin-bottom: 30px;
	position: relative;
	z-index: 10;
}

/**
------------------------------------------------------------------------------------------------------------------------
home-counter-sec
------------------------------------------------------------------------------------------------------------------------
 */
.home-counter-sec {
	margin-top: 60px;
	position: relative;
	background-size: cover;
	background-attachment: fixed;
	padding: 100px 0px 80px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-image: url(http://csrapatin.rs/wp-content/themes/csrapatin/img/home-counter-bg-1.jpg);
	&:before {
		position: absolute;
		content: "";
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		display: block;
		background-color: rgba(11, 32, 40, 0.9);
	}
	.sec-title {
		color: #fff;
	}

}

.counter-box {
	text-align: center;
	width: 160px;
	height: 160px;
	position: relative;
	margin: 0 auto;

	.icon-wrap {
		position: relative;
		z-index: 10;
		font-size: 62px;
		background: transparent;
		line-height: 1;
		display: inline-block;
		text-align: center;
		border-radius: 100%;

		color: #fff;
		margin-top: 30px;
	}
	.text-wrap {
		padding-top: 10px;
		position: relative;
		z-index: 10;
		.timer {
			font-size: 1.8rem;
			color: #fff;
			font-weight: 700;
		}
		p {
			color: #fff;
			font-weight: 700;
		}
	}
	.counter-box-bg {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 200px;
		background-color: $accent_color;
		width: 160px;
		height: 160px;
	}

}

.counter-box-title {
	margin-top: 12px;
	text-align: center;
	font-size: 1.2rem;
	color: #fff;
}

/**
------------------------------------------------------------------------------------------------------------------------
home-news-section
------------------------------------------------------------------------------------------------------------------------
 */
.home-news-section {
	padding-top: 80px;
	padding-bottom: 80px;

}

/**
------------------------------------------------------------------------------------------------------------------------
page-title
------------------------------------------------------------------------------------------------------------------------
 */
.page-title {
	position: relative;
	text-align: center;
	background-size: cover;
	background-attachment: fixed;
	color: rgb(255, 255, 255);
	padding: 60px 0px;
	@include breakpoint($min992) {
		padding: 130px 0px;
	}
	background-repeat: no-repeat;
	background-position: center center;
	margin-bottom: 30px;
	&:before {
		content: "";
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	.entry-title {
		position: relative;
		z-index: 10;
		font-size: 32px;
		font-weight: 800;
		color: #fff;
		letter-spacing: 1px;
		line-height: 1em;
		margin-top: 8px;
		text-transform: uppercase;
		font-family: Montserrat, sans-serif;
		margin-bottom: 0;
	}
	.bread-crumb {
		position: relative;
		padding-top: 5px;
		text-align: center;

		& > span {
			position: relative;
			display: inline-block;
			line-height: 30px;
			margin-left: 20px;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			text-transform: capitalize;
			a {
				color: $accent_color;
				transition: all 0.3s ease;
			}
		}
	}
}

.paddsec {
	padding: 80px 0;
}