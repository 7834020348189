html {
	min-height: 100%;
	//font-size: 62.5%;
}

body {
	font-family: $font_primary;
	color: $text_c;
	//@include font-size(16);
	font-weight: 400;
	img {
		max-width: 100%;
		display: block;
	}
	background-color: #dedede;
	background-image: url("https://csrapatin.rs/wp-content/themes/csrapatin/img/page-bg-grb-15.png");
	background-size: cover;
	background-attachment: fixed;
}

#map {
	height: 400px;
	width: 100%;
}
.contact-info-map{
	font-size: 14px;
	strong{
		font-weight: 700;
		display: block;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.grey-bg {
	background: $grey_bg;
}
/**
------------------------------------------------------------------------------------------------------------------------
logo-compose
------------------------------------------------------------------------------------------------------------------------
 */
.logo-compose-link {
	&:hover, &:focus {
		text-decoration: none;
	}
}

.logo-compose-text {
	.first {
		font-weight: 700;
		color: #fff;
	}
	.last {
		font-weight: 300;
		color: #fff;
	}
}

.logo-compose-img {
	width: 100px;
	height: 100px;
}

.logo-compose-text {
	font-size: 3rem;
}

.navbar-brand {
	.logo-compose-img {
		width: 40px;
		height: 40px;
	}
	.logo-compose-text {
		font-size: 1.4rem;
		@include breakpoint($min768) {
			font-size: 2rem;
		}

		.first {
			color: #fff;
		}
		.last {
			color: $divider_color;
		}
	}
}

/**
------------------------------------------------------------------------------------------------------------------------
decor-line
------------------------------------------------------------------------------------------------------------------------
 */
.decor-line {
	margin-top: 10px;
	display: inline-block;
	&:after, &:before {
		content: '';
		width: 40px;
		background: $accent_color;
		height: 2px;
		display: inline-block;
		vertical-align: middle;
	}
	.decor-line-inner {
		width: 26px;
		height: 16px;
		background-image: url(https://csrapatin.rs/wp-content/themes/csrapatin/img/csrapatin-Logo-image.svg);
		background-repeat: no-repeat;
		background-position: center center;
		display: inline-block;
		vertical-align: middle;
	}
}


